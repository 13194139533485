/*-----------------------------------------------
|   Isotope
-----------------------------------------------*/

.isotope-nav{
  font-weight: $font-weight-bold;
  border: 1px solid $white;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: var(--#{$variable-prefix}700);
  border-radius: $border-radius;
  letter-spacing: 0.04em;
  padding: map_get($spacers, 1) map_get($spacers, 2);
  &.active{
    color: $black !important;
    border: 1px solid $black;
  }
  &:hover{
    color: var(--#{$variable-prefix}700);
  }
}