/* -------------------------------------------------------------------------- */
/*                                  Accordion                                 */
/* -------------------------------------------------------------------------- */

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: $accordion-button-active-color;
    background-color: $accordion-button-active-bg;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

    &::before {
      background-image: escape-svg($accordion-button-active-icon);
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    display: none;
  }
  &::before {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    content: "";
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
    margin-right: 1rem;
  }

}