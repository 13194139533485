/*-----------------------------------------------
|   Process
-----------------------------------------------*/
.process-item {
  position: relative;
  display: inline-block;
  .process-item-number {
    position: absolute;
    color: $gray-300;
    left: 0;
    top: 0;
    transform: translate(-127%, -21%);
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-black;
    background-color: white;
    font-size: map_get($font-sizes, 4);

    @include media-breakpoint-up(sm) {
      font-size: map_get($font-sizes, 6);
    }
  }

  &.process-item-last .process-item-number:after {
    content: '';
    position: absolute;
    width: 1rem;
    background: $white;
    height: 1000rem;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
