/*-----------------------------------------------
|   Avatar
-----------------------------------------------*/
.avatar{ 
  width: 3.5rem;
  height: auto;
  object-fit: cover;
  border-radius: 50%;

  
}
